import { __assign, __rest } from "tslib";
/** @jsx jsx */
import * as React from "react";
import styled from "@emotion/styled/macro";
import { jsx } from "@emotion/core";
import defaultTheme from "../defaultTheme";
import { createPropsGetter } from "../utils";
import InputContainer from "../InputContainer";
import LabelContainer from "../LabelContainer";
import InputLabel from "../InputLabel";
import InputDescription from "../InputDescription";
import { FormattedMessage } from "react-intl";
import WarningImageIcon from "../WarningImageIcon";
import LogicTag from "../LogicTag";
import SourceLogicTag from "../SourceLogicTag";
import { generateAriaDescribedBy, sanitizeHtmlID } from "../utils/stringUtils";
import { Util } from "./TextInputUtil";
import { areaCodeDisplayMask, Util as ValidationUtil } from '../TextInputValidation/TextInputValidationUtil';
import CurrencyValidation from "../TextInputValidation/CurrencyValidation";
import { PhoneValidation } from "../TextInputValidation/PhoneValidation";
import EmailNumberValidation from "../TextInputValidation/EmailNumberValidation";
import { FormConfirmationType } from "@smartsheet/forms-model";
var defaultProps = {
    required: false,
    readonly: false,
    touched: false,
    hidden: false,
    disabled: false,
    isRichTextEnabled: false,
    validation: { type: '', country: '', code: '', format: '', isPostFix: false },
    lines: 1,
    conditional: false,
    onPhoneChange: function (value) { },
    onChange: function (event) { },
    onBlur: function (e) { }
};
var getProps = createPropsGetter(defaultProps);
export var Input = styled.input(function (_a) {
    var _b = _a.theme, theme = _b === void 0 ? defaultTheme : _b, error = _a.error, props = __rest(_a, ["theme", "error"]);
    return "\n  " + Util.textInputBaseClass + "\n  font-family: " + defaultTheme.style.font.family + ";\n  color: " + defaultTheme.style.font.editableColor + ";\n  border: 1px solid " + (error ? "#d0011b" : "#ccc") + ";  \n  height: 30px;\n  padding: 0 7px 0 7px;\n  &:hover {\n    border-color: " + (error ? "#d0011b" : "#A0A0A0") + "\n  }\n  &:focus {\n    outline: 0;\n    border-color: " + (error ? "#d0011b" : "#005EE0") + "\n  }\n";
});
var Textarea = styled.textarea(function (_a) {
    var _b = _a.theme, theme = _b === void 0 ? defaultTheme : _b, error = _a.error, props = __rest(_a, ["theme", "error"]);
    return "\n  " + Util.textInputBaseClass + "\n  color: " + defaultTheme.style.font.editableColor + ";\n  font-family: " + defaultTheme.style.font.family + ";\n  border: 1px solid " + (error ? "#d0011b" : "#ccc") + ";\n  line-height: 15px;\n  margin-left: 0;\n  margin-right: 0;\n  margin-top: 7px;\n  margin-bottom: -7px;\n  padding: 7px;\n  resize: none;\n  &:hover {\n    border-color: " + (error ? "#d0011b" : "#A0A0A0") + "\n  }\n  &:focus {\n    outline: 0;\n    border-color: " + (error ? "#d0011b" : "#005EE0") + "\n  }  \n  ";
});
var TextInput = function (props) {
    var _a;
    var _b = React.useState(''), displayMask = _b[0], setdisplayMask = _b[1];
    var prevDisplayMask = Util.usePrevious(displayMask);
    var _c = getProps(props), name = _c.name, value = _c.value, label = _c.label, logic = _c.logic, isHiddenByConditionalLogic = _c.isHiddenByConditionalLogic, hasSourceLogic = _c.hasSourceLogic, onSourceLogicTagClick = _c.onSourceLogicTagClick, localizationLabelId = _c.localizationLabelId, description = _c.description, richDescription = _c.richDescription, lines = _c.lines, validation = _c.validation, required = _c.required, disabled = _c.disabled, onChange = _c.onChange, onPhoneChange = _c.onPhoneChange, defaultValue = _c.defaultValue, onBlur = _c.onBlur, error = _c.error, componentKey = _c.componentKey, isRichTextEnabled = _c.isRichTextEnabled, innerInputRef = _c.innerInputRef, submitCount = _c.submitCount, confirmation = _c.confirmation;
    var options = ValidationUtil.options, countryPhoneFormat = ValidationUtil.countryPhoneFormat, ValidationTypes = ValidationUtil.ValidationTypes, InputTypes = ValidationUtil.InputTypes, MaxLength = ValidationUtil.MaxLength;
    var LogicInputLabel = Util.LogicInputLabel, ErrorSpan = Util.ErrorSpan, WarningImageStyled = Util.WarningImageStyled;
    var type = '';
    var currencyCountry = '';
    var currencyCode = '';
    var validationType = '';
    var prefix = '';
    var suffix = '';
    var areaCodeLength;
    var areaCode;
    var shouldHavePhoneCountry = validation && validation.type && validation.type === ValidationTypes.PHONE;
    var initialPhoneCounty;
    if (shouldHavePhoneCountry) {
        initialPhoneCounty = validation.country != null ? validation.country : 'us';
    }
    else {
        initialPhoneCounty = "";
    }
    var _d = React.useState(initialPhoneCounty), phoneCountry = _d[0], setPhoneCountry = _d[1];
    var _e = React.useState(confirmation === null || confirmation === void 0 ? void 0 : confirmation.type), confirmationType = _e[0], _ = _e[1];
    if (validation && validation.type) {
        if (validation.type === ValidationTypes.NUMERIC) {
            type = InputTypes.NUMBER;
            validationType = ValidationTypes.NUMERIC;
        }
        else if (validation.type === ValidationTypes.PERCENTAGE) {
            type = InputTypes.NUMBER;
            validationType = ValidationTypes.PERCENTAGE;
        }
        else if (validation.type === ValidationTypes.EMAIL) {
            type = InputTypes.EMAIL;
            validationType = ValidationTypes.EMAIL;
        }
        else if (validation.type === ValidationTypes.PHONE) {
            validationType = ValidationTypes.PHONE;
        }
        else if (validation.type === ValidationTypes.CURRENCY) {
            if (validation.isPostFix) {
                suffix = validation.code;
            }
            else {
                prefix = validation.code;
            }
            validationType = ValidationTypes.CURRENCY;
            currencyCountry = options.filter(function (format) {
                return format.value === validation.country;
            })[0].label;
            currencyCode = validation.code;
        }
    }
    var _f = React.useState((defaultValue === null || defaultValue === void 0 ? void 0 : defaultValue.value) || ""), phoneNumber = _f[0], setPhoneNumber = _f[1];
    React.useEffect(function () {
        setdisplayMask(countryPhoneFormat[phoneCountry]);
        var text_element_id = "text_box_" + (props.label != null ? props.label : props.name);
        var text_element = document.getElementById(text_element_id);
        if (text_element != null) {
            // App-core default for all text box, so enable right click
            text_element.smartSheetAllowContextMenu = true;
        }
    }, []);
    var handleKeyDown = function (e) {
        if (e.key === "Enter") {
            e.preventDefault();
        }
    };
    React.useEffect(function () {
        if (validationType === ValidationTypes.PHONE) {
            // this makes sure the country code prop changes when a new country is selected
            if (displayMask) {
                areaCodeLength = displayMask.indexOf(" ") - (displayMask.indexOf("+") + 1);
                areaCode = displayMask.slice(1, areaCodeLength + 1);
                setPhoneCountry(areaCodeDisplayMask[areaCode][0]);
            }
            if (confirmationType === FormConfirmationType.RELOAD) {
                // if submitCount = 0, that means a submission has not occurred and the phone number is either set to its defaultValue or is cleared
                if (!submitCount) {
                    // Here submitCount = 0
                    var isNum = /^\d+$/.test(value || "");
                    // this logic tells us if the form was already submitted or not.
                    // right when a form is submitted, the phone number will be an empty string, 
                    // and the value will be strictly numeric
                    // once this is realized, the default state is restored if there is a default value
                    if (value && isNum && defaultValue) {
                        setdisplayMask(countryPhoneFormat[validation.country]);
                        setPhoneNumber(defaultValue.value);
                    }
                    else {
                        setPhoneNumber("");
                    }
                }
            }
        }
    }, [submitCount, displayMask]);
    var onPhoneInputBlur = function (event) {
        var value = event.target.value;
        if (Util.isPhoneNumberFieldEmpty(value)) {
            onPhoneChange("");
        }
        else {
            onPhoneChange(value);
        }
    };
    var hasLogic = logic != null;
    var dataClientIdSuffix = label != null ? label : name;
    var forElement = lines > 1
        ? "textarea_" + dataClientIdSuffix
        : "text_box_" + dataClientIdSuffix;
    var descriptionElemId = "description_" + sanitizeHtmlID(dataClientIdSuffix);
    var errorElemId = "error_msg_" + sanitizeHtmlID(dataClientIdSuffix);
    var describedByIds = generateAriaDescribedBy(descriptionElemId, !!description, errorElemId, !!error);
    return (jsx(InputContainer, { "data-client-id": "container_" + dataClientIdSuffix, "data-client-type": "text", css: {
            '& p': {
                marginTop: '5px',
                marginBlock: '2px',
                fontSize: '14.12px',
            },
            '& ul': {
                listStylePosition: 'inside',
                fontSize: '14.12px',
                marginBlock: '2px'
            },
            '& ol': {
                listStylePosition: 'inside',
                fontSize: '14.12px',
                marginBlock: '2px'
            },
            display: 'block'
        }, id: componentKey },
        localizationLabelId && (jsx(InputLabel, { dataClientIdSuffix: dataClientIdSuffix, forElement: forElement, required: required, localizationLabelId: localizationLabelId }, " ")),
        label && !hasLogic && !hasSourceLogic && (jsx(InputLabel, { dataClientIdSuffix: dataClientIdSuffix, forElement: forElement, required: required }, label)),
        label && (hasLogic || hasSourceLogic) && (jsx(LabelContainer, null,
            jsx(LogicInputLabel, null,
                jsx(InputLabel, { dataClientIdSuffix: dataClientIdSuffix, forElement: forElement, required: required }, label)),
            hasSourceLogic && (jsx(SourceLogicTag, { onClickHandler: onSourceLogicTagClick })),
            logic && jsx(LogicTag, { logic: logic }))),
        (isRichTextEnabled && richDescription) &&
            jsx("div", { id: descriptionElemId, className: "rich-text-field-desc", dangerouslySetInnerHTML: { __html: richDescription } }) ||
            description && jsx(InputDescription, { id: descriptionElemId }, description),
        lines > 1 ? ( // If conditionally hidden, set tabIndex to -1, so tab just ignores hidden field
        jsx(Textarea, __assign({ "aria-invalid": !!error }, (describedByIds && (_a = {}, _a['aria-describedby'] = describedByIds, _a)), { tabIndex: isHiddenByConditionalLogic ? -1 : 0, name: name, id: "textarea_" + dataClientIdSuffix, "data-client-id": "textarea_" + dataClientIdSuffix, disabled: disabled, value: value, maxLength: MaxLength, rows: lines, onBlur: onBlur, onChange: onChange, error: error }))) :
            (validationType === ValidationTypes.CURRENCY ?
                jsx(CurrencyValidation, { ariaInvalid: !!error, describedByIds: describedByIds, prefix: prefix, suffix: suffix, tabIndex: isHiddenByConditionalLogic ? -1 : 0, id: forElement, dataClientId: forElement, onValueChange: function (value) {
                        onPhoneChange(value.formattedValue);
                    } })
                :
                    phoneCountry !== '' ?
                        jsx(PhoneValidation, { name: name, onChange: function (phone, country) {
                                setdisplayMask(countryPhoneFormat[country.countryCode]);
                            }, displayMask: displayMask, phoneNumber: phoneNumber, phoneCountry: phoneCountry, ariaInvalid: !!error, describedByIds: describedByIds, tabIndex: isHiddenByConditionalLogic ? -1 : 0, id: forElement, dataClientId: forElement, defaultValue: Number(defaultValue === null || defaultValue === void 0 ? void 0 : defaultValue.value), onBlur: onPhoneInputBlur, onValueChange: function (value) {
                                setPhoneNumber(value.value);
                                if (Util.isPhoneNumberFieldFull(value.formattedValue) || prevDisplayMask && (displayMask !== prevDisplayMask)) {
                                    onPhoneChange(value.formattedValue);
                                }
                            } })
                        :
                            jsx(EmailNumberValidation, { title: currencyCountry, ariaInvalid: !!error, describedByIds: describedByIds, tabIndex: isHiddenByConditionalLogic ? -1 : 0, dataClientId: forElement, id: forElement, validationType: validationType, type: type, ref: innerInputRef, name: name, disabled: disabled, value: value, onBlur: onBlur, onChange: onChange, handleKeyDown: handleKeyDown, error: error, style: { width: type === InputTypes.NUMBER ? (validationType === ValidationTypes.CURRENCY && !validation.isPostFix ? '283px' : '300px') : '' } })),
        error && (jsx(ErrorSpan, { "data-client-id": "component_error_msg" },
            jsx(WarningImageIcon, { customCss: WarningImageStyled }),
            jsx("span", { role: "alert", id: errorElemId }, name === "EMAIL_RECEIPT" && value != "" ? (jsx(FormattedMessage, { id: "messages.msg_fieldEmailValidation" })) : error === 'phone error' ?
                (!!value ?
                    jsx(FormattedMessage, { id: "messages.msg_fieldPhoneValidation" })
                    :
                        required ?
                            jsx(FormattedMessage, { id: "messages.msg_fieldRequired" })
                            :
                                jsx(FormattedMessage, { id: "messages.msg_fieldPhoneValidation" })) : typeof error === 'string' && !!value ?
                (jsx(FormattedMessage, { id: "messages.msg_fieldEmailValidation" })) : (jsx(FormattedMessage, { id: "messages.msg_fieldRequired" })))))));
};
export default TextInput;
